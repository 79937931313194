import { useId, useState } from 'react';

import classNames from 'classnames';

import { AdCloseButton } from './AdCloseButton';
import { ADS_CONSTANTS } from './AdsConstants';
import { useAppendScript } from './hooks';

const GlobalFloatingBottomRightBannerTextButton = ({
  className,
  userId,
}: {
  className?: string;
  userId?: number;
}) => {
  const id = useId();

  const [showAd, setIsShowAd] = useState(true);

  useAppendScript({ id, userId });

  const handleClick = () => {
    setIsShowAd(false);
  };

  if (!showAd) {
    return null;
  }

  return (
    <div
      className={classNames(
        'fixed right-5 max-md-msq:hidden rounded-lg border border-base-border-medium bg-base-bg overflow-hidden z-10 shadow-xl w-[332px] xl-msq:bottom-5',
        className,
      )}
      id={id}
    >
      <AdCloseButton onClick={handleClick} />
      <ins
        className={ADS_CONSTANTS.className}
        data-cp-user-id={userId}
        data-key={ADS_CONSTANTS.globalFloatingBottomRightBannerTextButtonDataKey}
      ></ins>
    </div>
  );
};

export default GlobalFloatingBottomRightBannerTextButton;
